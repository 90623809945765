// import Konva from "konva"

import "@/plugins/composition-api";

import Konva from "konva";

export type TemplateItemType =
  | "Text"
  | "Rect"
  | "Circle"
  | "Image"
  | "ParticipantImage"
  | "ParticipantQR"
  | "ParticipantDegree"
  | "ParticipantName"
  | "ParticipantFirstName"
  | "ParticipantLastName"
  | "ParticipantGroup"
  | "ParticipantInstitution"
  | "ParticipantLicenseNumber"
  | "ParticipantPoints";

export type TemplateItemShapes =
  | Konva.Shape
  | Konva.Image
  | Konva.Text
  | Konva.Rect
  | Konva.Circle;

export interface TemplateItem {
  type: TemplateItemType;
  config: Konva.ShapeConfig;
}

export interface TemplateSettings {
  width: number;
  height: number;
  margin?: number;
  cornerRadius?: number;
}

export interface TemplateShape extends TemplateItem {
  shape: Konva.Shape;
}

export default function() {
  const clipRect = (
    ctx: Konva.Context,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number
  ) => {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();

    return ctx;
  };

  return { clipRect };
}
