

import '@/plugins/composition-api'

import { computed, defineComponent, nextTick, onMounted, PropType, ref, Ref, watch, WritableComputedRef } from '@vue/composition-api'
import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'
import { breakpointsTailwind, onKeyStroke, useEventListener, useVModel, useWindowSize } from '@vueuse/core'

import type { TemplateItemType, TemplateItemShapes, TemplateItem, TemplateSettings } from '@/use/templates/editor'
import useEditor from '@/use/templates/editor'
import useHelper from '@/use/templates/helper'
import useEditorData from '@/use/templates/editorData'
import useEditorFonts from '@/use/templates/editorFonts'
import useEditorConfig from '@/use/templates/editorConfig'

import { v4 as uuid } from 'uuid'

export default defineComponent({
  components: {
    OTemplateEditorSettings: () => import('./o-template-editor-settings.vue'),
    OTemplateEditorShapes: () => import('./o-template-editor-shapes.vue'),
    OTemplateEditorToolbar: () => import('./o-template-editor-toolbar.vue')
  },
  props: {
    value: {
      type: Array as PropType<TemplateItem[]>,
      required: false,
      default: () => []
    },
    config: {
      type: Object as PropType<TemplateSettings>,
      required: false,
      default: () => ({ width: 2480, height: 3508 })
    }
  },
  setup(props, { emit }) {
    const { clamp } = useHelper()
    const { clipRect } = useEditor()
    const { editorImages } = useEditorData()
    const { loadFonts } = useEditorFonts()
    const { sceneWidth, sceneHeight, scaleBy, GUIDELINE_OFFSET, templateItemShapes, defaultConfig } = useEditorConfig()

    // const emit = defineEmits(['update:modelValue'])

    const model: Ref<TemplateItem[]> | WritableComputedRef<TemplateItem[]> = useVModel(props, 'value', emit, { deep: true, eventName: 'input' })

    const shapes = computed<TemplateItemShapes[]>(() => model.value.map(item => {
      const config = {
        ...item.config as any,
        image: undefined
      }

      if (item.config.image || item.type === 'Image') {
        const image = new Image()
        image.src = item.config.image ?? editorImages.value.broken
        config.image = image
      }

      return new (templateItemShapes[item.type] ?? Konva.Shape)(config)
    }))

    const selectedShapes = ref([]) as Ref<TemplateItemShapes[]>

    const wrapper = ref() as Ref<HTMLDivElement>
    const container = ref() as Ref<HTMLDivElement>

    const stage = ref() as Ref<Konva.Stage>
    const layer = ref() as Ref<Konva.Layer>

    const background = ref() as Ref<Konva.Rect>

    const scaleCoin = ref() as Ref<Konva.Image>
    const scalePlant = ref() as Ref<Konva.Image>

    const paper = ref() as Ref<Konva.Rect>
    const margin = ref() as Ref<Konva.Rect>

    const group = ref() as Ref<Konva.Group>

    const selectionRectangle = ref() as Ref<Konva.Rect>
    const transformer = ref() as Ref<Konva.Transformer>

    const selection = ref<{ x1?: number; y1?: number; x2?: number; y2?: number }>({ x1: 0, y1: 0, x2: 0, y2: 0 })

    const scale = ref(1)
    const stroke = computed(() => stage.value.width() / scale.value / sceneWidth)

    const marginEnabled = ref(true)
    const scaleEnabled = ref(true)

    watch([marginEnabled, scaleEnabled], ([marginState, scaleState]) => {
      margin.value.visible(marginState)
      scaleCoin.value.visible(scaleState)
      scalePlant.value.visible(scaleState)
    })

    /**
     * Create Konva and basic shapes
     */
    const init = () => {
      Konva.dragButtons = [0]

      const stageConfig: Konva.StageConfig = {
        container: container.value,
        width: sceneWidth,
        height: sceneHeight,
        draggable: true,
      }

      stage.value = new Konva.Stage(stageConfig)

      layer.value = new Konva.Layer()
      stage.value.add(layer.value)

      stage.value.on('mousedown', (e) => (e.evt.button === 1) && stage.value.startDrag())
      stage.value.on('mouseup', () => stage.value.stopDrag())

      const backgroundConfig: Konva.RectConfig = {
        width: sceneWidth * 20 + props.config.width,
        height: sceneHeight * 20 + props.config.height,
        x: sceneWidth * -10,
        y: sceneHeight * -10,
        fill: 'rgb(243, 244, 246)',
        listening: false
      }

      background.value = new Konva.Rect(backgroundConfig)
      layer.value.add(background.value)
      background.value.zIndex(0)

      const scaleCoinSize = 271.653543307
      const scaleCoinConfig: Konva.ImageConfig = {
        width: scaleCoinSize,
        height: scaleCoinSize,
        x: props.config.width - scaleCoinSize / 2,
        y: -scaleCoinSize / 2,
        image: undefined,
        listening: false
      }

      scaleCoinConfig.image = new Image()
      scaleCoinConfig.image.src = editorImages.value.coin


      scaleCoin.value = new Konva.Image(scaleCoinConfig)
      layer.value.add(scaleCoin.value)
      scaleCoin.value.zIndex(1)

      const paperConfig: Konva.RectConfig = {
        x: 0,
        y: 0,
        width: props.config.width,
        height: props.config.height,
        cornerRadius: props.config.cornerRadius ?? 4,
        fill: 'white',
        listening: false,
        shadowEnabled: true,
        shadowOffset: { x: 0, y: 8 },
        shadowBlur: 32,
        shadowColor: 'rgb(229, 231, 235)'
      }

      paper.value = new Konva.Rect(paperConfig)
      layer.value.add(paper.value)
      paper.value.zIndex(2)

      const marginConfig: Konva.RectConfig = {
        x: props.config.margin ?? 0,
        y: props.config.margin ?? 0,
        width: props.config.margin ? props.config.width - props.config.margin * 2 : props.config.width,
        height: props.config.margin ? props.config.height - props.config.margin * 2 : props.config.height,
        strokeWidth: 1,
        stroke: props.config.margin ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
        listening: false
      }

      margin.value = new Konva.Rect(marginConfig)
      layer.value.add(margin.value)
      margin.value.zIndex(3)

      const groupConfig: Konva.GroupConfig = {
        width: props.config.width,
        height: props.config.height,
        clipFunc: (ctx) => clipRect(ctx, 0, 0, props.config.width, props.config.height, props.config.cornerRadius ?? 4)
      }

      group.value = new Konva.Group(groupConfig)
      layer.value.add(group.value)
      group.value.zIndex(4)

      const scalePlantSize = 1500 * 2
      const scalePlantConfig: Konva.ImageConfig = {
        width: scalePlantSize,
        height: scalePlantSize,
        x: -scalePlantSize * 0.66,
        y: -scalePlantSize * 0.66,
        image: undefined,
        listening: false
      }

      scalePlantConfig.image = new Image()
      scalePlantConfig.image.src = editorImages.value.plant


      scalePlant.value = new Konva.Image(scalePlantConfig)
      layer.value.add(scalePlant.value)
      scalePlant.value.zIndex(5)

      const selectionRectangleConfig: Konva.RectConfig = {
        width: 0, height: 0,
        x: 0, y: 0,
        fill: 'rgba(13, 153, 255, 0.1)',
        stroke: '#0d99ff',
        strokeWidth: 1,
        visible: false,
        listening: false,
      }

      selectionRectangle.value = new Konva.Rect(selectionRectangleConfig)
      layer.value.add(selectionRectangle.value)
      selectionRectangle.value.zIndex(6)

      const transformerConfig: Konva.TransformerConfig = {
        rotationSnaps: [0, 45, 90, 135, 180, 225, 270, 315],
        anchorSize: 8,
        anchorFill: '#ffffff',
        anchorStroke: '#0d99ff',
        anchorStrokeWidth: 1,
        borderStroke: '#0d99ff',
        borderStrokeWidth: 1
      }

      transformer.value = new Konva.Transformer(transformerConfig)
      layer.value.add(transformer.value)
    }

    onMounted(init)

    /**
    * Update transformer nodes
    */
    const updateTransformer = () => {
      const selected = stage.value.find(selectedShapes.value.map(item => `#${item.id()}`).join(','))
      transformer.value.nodes([...(selected ?? [])])
    }

    watch(selectedShapes, updateTransformer)

    /**
    * Handle shape drag event
    * @param e drag event
    */
    const handleDrag = (e: Konva.KonvaEventObject<DragEvent>) => {
      model.value = model.value.map(item => {
        if (item.config.id === e.target.id()) {
          item.config.x = e.target.x()
          item.config.y = e.target.y()
        }

        return item
      })
    }

    /**
    * Handle shape transform event
    * @param e transform event
    */
    const handleTransform = (e: Konva.KonvaEventObject<any>) => {
      model.value = model.value.map(item => {
        const shape = selectedShapes.value.find(selectedItem => selectedItem.id() === item.config.id)
        if (shape) {
          item.config = {
            ...item.config,
            x: e.target.x(),
            y: e.target.y(),
            width: Math.max(e.target.width() * e.target.scaleX(), 5),
            height: Math.max(e.target.height() * e.target.scaleY(), 5),
            scaleX: 1,
            scaleY: 1,
            rotation: e.target.rotation()
          }
        }

        return item
      })
    }

    /**
    * Create new shapes when changes
    * @param value shape value
    */
    const createShapes = (value: Konva.Shape[]) => {
      nextTick(() => {
        const allowedIds = value.map(shape => shape.id())
        const shapes = group.value.find('Shape')
        const fonts: string[] = []

        shapes.forEach(shape => {
          if (!allowedIds.includes(shape.id())) shape.destroy()
        })

        value.forEach((item, index) => {
          item.attrs.zIndex = index

          if (item.attrs.fontFamily) fonts.push(item.attrs.fontFamily)

          const shape = shapes.find(shape => shape.id() === item.id())
          if (shape) {
            shape.setAttrs(item.attrs)
          } else {
            group.value?.add(item)
            item.on('transform', handleTransform)
            item.on('dragmove', handleDrag)
          }
        })

        if (fonts?.length) loadFonts(fonts)
      })
    }

    onMounted(() => createShapes(shapes.value))
    watch(shapes, createShapes, { deep: true, immediate: true })

    const zoomToFit = () => {
      const margin = 100
      const max = Math.max(props.config.width, props.config.height)
      const center = { x: props.config.width / 2, y: props.config.height / 2 }

      scale.value = sceneWidth / (max + 2 * margin) * scale.value

      const x = Math.abs(center.x - max / 2 - margin) * scale.value
      const y = Math.abs(center.y - max / 2 - margin) * scale.value

      stage.value.scale({ x: scale.value, y: scale.value })
      stage.value.position({ x, y })
    }

    /**
    * Resize canvas on mounted & on window resize
    */
    const fitStageIntoParent = () => {
      const containerWidth = container.value.offsetWidth
      scale.value = containerWidth / sceneWidth

      stage.value.width(sceneWidth * scale.value)
      stage.value.height(sceneHeight * scale.value)

      stage.value.scale({ x: scale.value, y: scale.value })

      zoomToFit()
    }

    onMounted(fitStageIntoParent)
    useEventListener('resize', fitStageIntoParent)

    const updateStrokes = () => {
      selectionRectangle.value.strokeWidth(stroke.value)
      margin.value.strokeWidth(stroke.value)
    }

    /**
    * Zoom on mouse scroll
    * @param e event
    */
    const handleScroll = (e: Konva.KonvaEventObject<WheelEvent>) => {
      e.evt.preventDefault()

      if (e.evt.altKey) {
        // zoom
        const oldScale = stage.value.scaleX()
        const pointer = stage.value.getPointerPosition() as Konva.Vector2d

        const mousePointTo = {
          x: (pointer.x - stage.value.x()) / oldScale,
          y: (pointer.y - stage.value.y()) / oldScale
        }

        const direction = e.evt.deltaY > 0 ? 1 : -1
        scale.value = clamp(direction > 0 ? oldScale * scaleBy : oldScale / scaleBy, 0.1, 1)

        stage.value.scale({ x: scale.value, y: scale.value })

        const newPos = {
          x: pointer.x - mousePointTo.x * scale.value,
          y: pointer.y - mousePointTo.y * scale.value
        }

        stage.value.position(newPos)

        updateStrokes()
      }
    }

    onMounted(() => stage.value.on('wheel', handleScroll))

    /**
    * Handle mouse down event on stage
    * @param e mouse event
    */
    const handleStageMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (e.evt.button === 0) stage.value.draggable(false)
      else return

      if (e.target.className !== stage.value.className) return
      e.evt.preventDefault()

      selection.value.x1 = stage.value.getRelativePointerPosition()?.x
      selection.value.y1 = stage.value.getRelativePointerPosition()?.y
      selection.value.x2 = stage.value.getRelativePointerPosition()?.x
      selection.value.y2 = stage.value.getRelativePointerPosition()?.y

      selectionRectangle.value.visible(true)
      selectionRectangle.value.width(0)
      selectionRectangle.value.height(0)
    }

    onMounted(() => stage.value.on('mousedown touchstart', handleStageMouseDown))

    /**
    * Handle mouse move event on stage
    * @param e mouse event
    */
    const handleStageMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (!selectionRectangle.value.visible()) return
      e.evt.preventDefault()

      selection.value.x2 = stage.value.getRelativePointerPosition()?.x
      selection.value.y2 = stage.value.getRelativePointerPosition()?.y

      if (selection.value.x1 && selection.value.y1  && selection.value.x2 && selection.value.y2) {
        selectionRectangle.value.setAttrs({
          x: Math.min(selection.value.x1, selection.value.x2),
          y: Math.min(selection.value.y1, selection.value.y2),
          width: Math.abs(selection.value.x2 - selection.value.x1),
          height: Math.abs(selection.value.y2 - selection.value.y1)
        })
      }
    }

    onMounted(() => stage.value.on('mousemove touchmove', handleStageMouseMove))

    /**
    * Handle mouse up event on stage
    * @param e mouse event
    */
    const handleStageMouseUp = (e: Konva.KonvaEventObject<MouseEvent>) => {
      stage.value.draggable(true)

      if (!selectionRectangle.value.visible()) return
      e.evt.preventDefault()

      setTimeout(() => selectionRectangle.value.visible(false))

      const box = selectionRectangle.value.getClientRect({ relativeTo: stage.value })
      const selected = shapes.value.filter(shape => Konva.Util.haveIntersection(box, shape.getClientRect({ relativeTo: stage.value })))
      selectedShapes.value = selected ? [...selected] : []
    }

    onMounted(() => stage.value.on('mouseup touchend', handleStageMouseUp))

    /**
    * Handle click event on stage
    * @param e mouse event
    */
    const handleStageClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (selectionRectangle.value.visible()) return

      const shape = shapes.value.find(shape => shape.id() === e.target.id())

      if (!shape) {
        selectedShapes.value = []
        return
      }

      const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey
      const isSelected = !!selectedShapes.value.find(item => item.id() === shape.id())

      if (!metaPressed && !isSelected) {
        selectedShapes.value = [shape]
      } else if (metaPressed && isSelected) {
        const nodes = selectedShapes.value.slice()
        nodes.splice(nodes.findIndex(item => item.id() === shape.id()), 1)
        selectedShapes.value = [...nodes]
      } else if (metaPressed && !isSelected) {
        selectedShapes.value = selectedShapes.value.concat([shape])
      }
    }

    onMounted(() => stage.value.on('click tap', handleStageClick))

    /**
    * Get available guide positions
    * @param skipShapes shapes to skip
    */
    const getLineGuideStops = (skipShape: Konva.Shape) => {
      const vertical = [0, group.value.width() / 2, group.value.width()]
      const horizontal = [0, group.value.height() / 2, group.value.height()]

      if (props.config.margin) {
        vertical.push(...[props.config.margin, group.value.width() - props.config.margin])
        horizontal.push(...[props.config.margin, group.value.height() - props.config.margin])
      }

      shapes.value.forEach(item => {
        if (item === skipShape) return

        const box = item.getClientRect({ relativeTo: stage.value })
        vertical.push(...[box.x, box.x + box.width, box.x + box.width / 2])
        horizontal.push(...[box.y, box.y + box.height, box.y + box.height / 2])
      })

      return { vertical, horizontal }
    }

    /**
    * Get snapping edges for shape
    * @param node shape to get edges for
    */
    const getObjectSnappingEdges = (node: Konva.Shape) => {
      const box = node.getClientRect()
      const absPos = node.absolutePosition()

      return {
        vertical: [
          { guide: Math.round(box.x), offset: Math.round(absPos.x - box.x), snap: 'start' },
          { guide: Math.round(box.x + box.width / 2), offset: Math.round(absPos.x - box.x - box.width / 2), snap: 'center' },
          { guide: Math.round(box.x + box.width), offset: Math.round(absPos.x - box.x - box.width), snap: 'end' },
        ],
        horizontal: [
          { guide: Math.round(box.y), offset: Math.round(absPos.y - box.y), snap: 'start' },
          { guide: Math.round(box.y + box.height / 2), offset: Math.round(absPos.y - box.y - box.height / 2), snap: 'center' },
          { guide: Math.round(box.y + box.height), offset: Math.round(absPos.y - box.y - box.height), snap: 'end' },
        ],
      }
    }

    const getGuides = (lineGuideStops: ReturnType<typeof getLineGuideStops>, itemBounds: ReturnType<typeof getObjectSnappingEdges>) => {
      const resultV: { lineGuide: number; diff: number; snap: string; offset: number }[] = []
      const resultH: { lineGuide: number; diff: number; snap: string; offset: number }[] = []

      lineGuideStops.vertical.forEach((lineGuide) => {
        itemBounds.vertical.forEach((itemBound) => {
          const diff = Math.abs(lineGuide - itemBound.guide)
          if (diff < GUIDELINE_OFFSET) {
            resultV.push({
              lineGuide: lineGuide,
              diff: diff,
              snap: itemBound.snap,
              offset: itemBound.offset,
            })
          }
        })
      })

      lineGuideStops.horizontal.forEach((lineGuide) => {
        itemBounds.horizontal.forEach((itemBound) => {
          const diff = Math.abs(lineGuide - itemBound.guide)
          if (diff < GUIDELINE_OFFSET) {
            resultH.push({
              lineGuide: lineGuide,
              diff: diff,
              snap: itemBound.snap,
              offset: itemBound.offset,
            })
          }
        })
      })

      const guides = []

      const minV = resultV.sort((a, b) => a.diff - b.diff)[0]
      const minH = resultH.sort((a, b) => a.diff - b.diff)[0]

      if (minV) guides.push({
        lineGuide: minV.lineGuide,
        offset: minV.offset,
        orientation: 'V',
        snap: minV.snap,
      })

      if (minH) guides.push({
        lineGuide: minH.lineGuide,
        offset: minH.offset,
        orientation: 'H',
        snap: minH.snap,
      })

      return guides
    }

    /**
    * Graw guides on layer
    * @param guides guides to draw
    */
    const drawGuides = (guides: ReturnType<typeof getGuides>) => {
      const lines: {
        lineGuide: number;
        offset: number;
        orientation: string;
        snap: string;
        line: Konva.Line;
      }[] = []

      guides.forEach((lg) => {
        if (lg.orientation === 'H') {
          const line = new Konva.Line({
            points: [-6000, 0, 6000, 0],
            stroke: '#f24822',
            strokeWidth: stroke.value,
            name: 'guid-line',
            // dash: [4 * stroke, 6 * stroke],
          });
          layer.value.add(line)
          line.position({
            x: 0,
            y: lg.lineGuide,
          });
          lines.push({ ...lg, line })
        } else if (lg.orientation === 'V') {
          const line = new Konva.Line({
            points: [0, -6000, 0, 6000],
            stroke: '#f24822',
            strokeWidth: stroke.value,
            name: 'guid-line',
            // dash: [4 * stroke, 6 * stroke],
          });
          layer.value.add(line)
          line.position({
            x: lg.lineGuide,
            y: 0,
          });
          lines.push({ ...lg, line })
        }
      })

      return lines
    }

    /**
    *
    * @param e
    */
    const handleLayerDragMove = (e: KonvaEventObject<any>) => {
      const shape = shapes.value.find(item => item.id() === e.target.id())
      if (!shape) return

      layer.value.find('.guid-line').forEach((l) => l.destroy())

      const lineGuideStops = getLineGuideStops(shape)
      const itemBounds = getObjectSnappingEdges(shape)

      const guides = getGuides(lineGuideStops, itemBounds)

      if (!guides.length) return

      drawGuides(guides)

      const absPos = shape.position()

      guides.forEach((lg) => {
        switch (lg.snap) {
          case 'start': {
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset
                break
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset
                break
              }
            }
            break;
          }
          case 'center': {
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset
                break
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset
                break
              }
            }
            break;
          }
          case 'end': {
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset
                break
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset
                break
              }
            }
            break
          }
        }
      })

      shape.position(absPos)
      model.value = model.value.map(item => {
        if (item.config.id === shape.id()) {
          item.config = {
            ...item.config,
            ...absPos
          }
        }
        return item
      })
    }

    onMounted(() => layer.value.on('dragmove', handleLayerDragMove))

    /**
    * Handle layer dragend event
    */
    const handleLayerDragEnd = () => {
      layer.value.find('.guid-line').forEach((l) => l.destroy())
    }

    onMounted(() => layer.value.on('dragend', handleLayerDragEnd))

    /**
    *
    * @param oldPos
    * @param newPos
    * @param event
    */
    const anchorDragBoundFunc = (oldPos: Konva.Vector2d, newPos: Konva.Vector2d, event: MouseEvent): Konva.Vector2d => {
      const shape = shapes.value.find(item => item.id() === selectedShapes.value[0]?.id())
      if (!shape) return newPos

      layer.value.find('.guid-line').forEach((l) => l.destroy())

      if (transformer.value.getActiveAnchor() === 'rotater') return newPos

      const dist = Math.sqrt(Math.pow(newPos.x - oldPos.x, 2) + Math.pow(newPos.y - oldPos.y, 2))
      if (dist > GUIDELINE_OFFSET) return newPos

      const lineGuideStops = getLineGuideStops(shape)

      const pointer = stage.value.getRelativePointerPosition()
      if (!pointer) return newPos

      const itemBounds = {
        vertical: [
          { guide: pointer.x, offset: pointer.x, snap: 'start' },
          { guide: pointer.x, offset: pointer.x, snap: 'center' },
          { guide: pointer.x, offset: pointer.x, snap: 'end' },
        ],
        horizontal: [
          { guide: pointer.y, offset: pointer.y, snap: 'start' },
          { guide: pointer.y, offset: pointer.y, snap: 'center' },
          { guide: pointer.y, offset: pointer.y, snap: 'end' },
        ]
      }

      const guides = getGuides(lineGuideStops, itemBounds)

      if (!guides.length) return newPos

      const lines = drawGuides(guides)

      const closestX = lines.sort((a, b) => {
        const ax = a.line.getAbsolutePosition().x
        const bx = b.line.getAbsolutePosition().x
        const adist = Math.abs(ax - pointer.x)
        const bdist = Math.abs(bx - pointer.x)
        if (adist < bdist) return -1
        if (adist > bdist) return 1
        return 0
      })[0].line.getAbsolutePosition().x ?? 0

      const diffX = Math.abs(newPos.x - closestX)

      const closestY = lines.sort((a, b) => {
        const ay = a.line.getAbsolutePosition().y
        const by = b.line.getAbsolutePosition().y
        const adist = Math.abs(ay - pointer.y)
        const bdist = Math.abs(by - pointer.y)
        if (adist < bdist) return -1
        if (adist > bdist) return 1
        return 0
      })[0].line.getAbsolutePosition().x ?? 0

      const diffY = Math.abs(newPos.y - closestY)

      const snappedX = diffX < GUIDELINE_OFFSET
      const snappedY = diffY < GUIDELINE_OFFSET

      if (snappedX && !snappedY) return { x: closestX, y: oldPos.y }
      else if (snappedY && !snappedX) return { x: oldPos.x, y: closestY }
      else if (snappedX && snappedY) return { x: closestX, y: closestY }

      return newPos
    }

    onMounted(() => transformer.value.anchorDragBoundFunc(anchorDragBoundFunc))

    /**
    *
    */
    const handleTransformEnd = () => {
      layer.value.find('.guid-line').forEach((l) => l.destroy())
    }

    onMounted(() => transformer.value.on('transformend', handleTransformEnd))

    /**
    * Generate dynamic shape name
    * @param type shape type
    */
    const createName = (type: TemplateItemType) => {
      let number = 1
      let name = `${type}${number}`
      while (model.value.find(item => item.config.name === name)) {
        number++
        name = `${type}${number}`
      }
      return name
    }

    /**
    * Create shape item
    * @param type shape type
    */
    const create = (type: TemplateItemType) => {
      const dynamicConfig: Konva.ShapeConfig = {
        id: uuid(),
        name: createName(type)
      }

      const config: Konva.ShapeConfig = {
        ...defaultConfig.GLOBAL,
        ...defaultConfig[type],
        ...dynamicConfig
      }
      const item = { type, config }

      model.value = [ ...model.value, item ]
    }

    /**
    * Delete specific item by id
    * @param id item id
    */
    const deleteItem = (id: string) => {
      model.value = model.value.filter(item => item.config.id !== id)
    }

    /**
    * delete all selected items
    */
    const deleteSelectedItems = () => {
      const selected = selectedShapes.value.map(item => item.id())
      model.value = model.value.filter(item => !selected.includes(item.config.id as string))
      selectedShapes.value = []
    }

    onKeyStroke('Delete', deleteSelectedItems)

    /**
    * delete all selected items
    */
    const deselectItems = () => {
      selectedShapes.value = []
    }

    onKeyStroke('Escape', deselectItems)

    /**
    * move selected items
    */
    const moveSelectedWithKeyboard = (e: KeyboardEvent) => {
      e.preventDefault()

      const vector = {
        x: e.key === 'ArrowLeft' ? -1 : e.key === 'ArrowRight' ? 1 : 0,
        y: e.key === 'ArrowUp' ? -1 : e.key === 'ArrowDown' ? 1 : 0,
      }

      console.log(vector)

      model.value = model.value.map(item => {
        const shape = selectedShapes.value.find(shape => shape.id() === item.config.id)

        if (shape) {
          const x = (item.config.x ?? 0) + vector.x
          const y = (item.config.y ?? 0) + vector.y

          item.config = {
            ...item.config,
            x,
            y,
          }
        }

        return item
      })
    }

    onKeyStroke(['ArrowUp', 'ArrowLeft', 'ArrowRight', 'ArrowDown'], moveSelectedWithKeyboard)

    const zoomIn = () => {
      const oldScale = stage.value.scaleX()
      scale.value = Math.round((oldScale + 0.1) * 10) / 10
      stage.value.scale({ x: scale.value, y: scale.value })
    }

    const zoomOut = () => {
      const oldScale = stage.value.scaleX()
      scale.value = Math.round((oldScale - 0.1) * 10) / 10
      stage.value.scale({ x: scale.value, y: scale.value })
    }

    const { width: screenWidth } = useWindowSize()
    const isDisabled = computed(() => screenWidth.value < breakpointsTailwind['2xl'])

    return { wrapper, container, marginEnabled, scaleEnabled, create, model, shapes, selectedShapes, isDisabled }
  }
})
